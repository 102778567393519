import "./TalentCard.scss"

import React, { FC, useRef } from "react"
import Image from "../Image";

export interface TalentCardProps {
  talent: any;
}

const TalentCard: FC<TalentCardProps> = ({ talent }) => {
  const card = useRef<HTMLDivElement>();

  return (
    <div ref={card} className={`talent-card talent-card--in-view`}>
      <a
        href={`/${talent.slug.current}`}
        className="talent-card__link"
        data-cursor-rotate-text={talent.shortName}
      >
        <Image image={talent.image} title={talent.name} className="talent-detail__image" />
        <Image image={talent.imageHover} title={talent.name} className="talent-card__img-hover" />
      </a>
    </div>
  )
}

export default TalentCard
