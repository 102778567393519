import "normalize.css";
import "./TalentListTemplate.scss";

import React, { FC, useContext, useEffect, useRef, useState } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";

import HomeSliderContact from "../../components/HomeSliderContact";
import Button from "../../components/Button";
import TalentList from "../../components/TalentList";
import Filters from "../../components/Filters";
import SectionHeader from "../../components/SectionHeader";
import { graphql } from "gatsby";
import { t } from "i18next";
import { PageTemplateProps } from "../../constants/general";
import Slider from "../../components/Slider";
import { getCurrentLanguage } from "../../i18n";

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const currentLanguage = getCurrentLanguage();
  const talentList = data.talent.nodes.filter(x => x.slug.current.includes(`${currentLanguage}/`))

  const currentSlug = pageContext.slug;

  const pageTitle = `${t("talents.pageTitle")} Youplanet`;
  const description = `${t("talents.pageTitle")} Youplanet`;
  const alternativeUrls = pageContext.alternativeUrls;

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(null);
  const { isMobile } = useContext(NavContext);

  const section = useRef<HTMLDivElement>();
  
  const buttonShowFilterClickHandler = () => {
    setOpenFilters(true);
  };

  const buttonApplyFilterClickHandler = () => {
    setOpenFilters(false);
  };

  const getCategories = (talents: any[]) => {
    const categories: { [key: string]: string } = {};

    talents.forEach((talent) => {
      talent.categories.filter(c => !!c).forEach((category) => {
        categories[category?.slug?.current] = category.name;
      });
    });
    return categories;
  };

  const getFilterIndex = (talents: any[]) => {
    const index: { [key: string]: string[] } = {};

    talents.forEach((talent) => {
      talent.categories.forEach((category) => {
        if (!index[category?.slug?.current]) {
          index[category?.slug?.current] = [];
        }
        index[category?.slug?.current].push(talent);
      });
    });
    return index;
  };

  const filterOptions = getCategories(talentList);

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  function pageContent() {
    const elements: JSX.Element[] = [
      <div className="talents">
        <div className="talents__list scrollable">
          <div className={`talents__button-show-filter `}>
            <Button
              clickHandler={buttonShowFilterClickHandler}
              uppercase={true}
            >
              {t("filters.button")}
            </Button>
          </div>
          <TalentList
            filterIndex={getFilterIndex(talentList)}
            talents={talentList}
          />
        </div>
      </div>,
      <HomeSliderContact />,
    ];
    return elements.map((el, index) => <span key={`${index}-talentListTemplate`}>{el}</span>);
  }

  const getContent = () => {
    return isMobileActive ? (
      pageContent()
    ) : (
      <Slider hidePagination>{pageContent()}</Slider>
    );
  }

  return (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeSlug={t("common.network")}
        alternativeText={t("common.network")}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={currentLanguage}
          slug={currentSlug}
        />
        <SectionHeader h1={t("talents.h1")} inView={true} />
        <section ref={section} className="talents-container">
          <Filters
            options={filterOptions}
            opened={openFilters}
            applyButtonHandler={buttonApplyFilterClickHandler}
          />
          {getContent()}
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query TalentListTemplateQuery($lang: String) {
    talent: allSanityTalent(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        shortName
        slug {
          current
        }
        categories: category {
          name
          slug {
            current
          }
        }
        image {
          ...ImageFragment
        }
        imageHover {
          ...ImageFragment
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
