import "./TalentList.scss"

import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { useWindowSize } from "../../hooks/useWindowSize";
import TalentCard from "../../components/TalentCard";
import NavContext from "../../components/Layout/Nav/NavProvider";

export interface TalentListProps {
  talents: any[];
  filterIndex: { [key: string]: string[] };
}

const TalentList: FC<TalentListProps> = ({ talents, filterIndex }) => {
  const { filters } = useContext(NavContext);
  const windowSize = useWindowSize();
  const [talentsItems, setTalentsItems] = useState<any[]>([]);
  const talentList = useRef<HTMLUListElement>();
  const cards = useRef<HTMLLIElement[]>([]);

  const calculateRowItems = () => {
    if (!talentList.current || !cards.current || !cards.current.length) {
      return;
    }

    cards.current
      .filter(card => !!card)
      .forEach((card, index) => {
        card.style.setProperty('--delay', `${(index * 0.1)}s`);
      });
  };
  
  useEffect(calculateRowItems, [talentsItems, windowSize]);

  useEffect(() => {
    if (!filters.length) {
      setTalentsItems(talents);
      return;
    }

    let filteredTalents = [];
    filters.forEach(filter => {
      const matchedTalents = filterIndex[filter] || [];
      filteredTalents = [...filteredTalents, ...matchedTalents];
    });

    const filteredTalentsUniques = Array.from(new Set(filteredTalents));
    setTalentsItems(filteredTalentsUniques);

  }, [talents, filters]);

  return (
    <ul ref={talentList} className='talent-list'>      
    {
      talentsItems.map((talent, index) => {
        return (
        <li
          ref={el => cards.current[index] = el}
          key={talent.slug.current}
          className='talent-list__item'
        >
          <TalentCard talent={talent} />
        </li>
        )
      })
    }
    </ul>
  )
}
export default TalentList
